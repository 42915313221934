<template>
  <div>
    <portal to="header">
      <terminal-header
        :title="$t('Pallet receiving')"
        :out-focused="isBlocked"
        @barcode="handleBarcode"
      />
    </portal>

    <q-carousel
      :model-value="action"
      transition-prev="scale"
      transition-next="scale"
      swipeable
      animated
      class="terminal-min-height"
    >
      <q-carousel-slide name="scanBasket" class="q-pa-none">
        <jumbotron />
      </q-carousel-slide>

      <q-carousel-slide name="next" class="relative q-pa-none">
        <pallet-receiving-content
          :barcode="barcode"
          :queues="queues"
          :place="place"
          @block="handleBlock"
          @unblock="handleUnblock"
          @reset="handleReset"
        />
      </q-carousel-slide>
    </q-carousel>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'

// Vuex
import { mapActions, mapMutations } from 'vuex'

export default {
  name: 'Sorting',
  components: {
    PalletReceivingContent: defineAsyncComponent(() => import('./PalletReceivingContent.vue'))
  },
  data () {
    return {
      action: 'scanBasket',
      queues: [],
      isBlocked: false,
      barcode: null,
      place: null,
      helper: null
    }
  },
  mounted () {
    this.loadQueues()
  },
  unmounted () {
    if (this.helper) {
      this.removeHelper(this.helper.id)
    }

    if (this.place) {
      this.$channels.user.publish('closePlace', this.place)
    }
  },
  methods: {
    ...mapMutations([
      'addErrorNotification',
      'removeHelper'
    ]),
    ...mapActions([
      'addHelper'
    ]),
    handleBlock () {
      this.isBlocked = true
    },
    handleUnblock () {
      this.isBlocked = false
    },
    handleReset () {
      this.action = 'scanBasket'
      this.queues = []
      this.isBlocked = false
      this.barcode = null

      if (this.place) {
        this.$channels.user.publish('closePlace', this.place)
      }

      this.place = null
    },
    loadQueues () {
      const query = {
        filter: [
          { type: 'eq', field: 'adapter', value: 'pallets' }
        ]
      }

      return this.$service.picking.getAll(query)
        .then(({ items }) => {
          this.queues = items.map(data => {
            return {
              type: 'Orderadmin\\Storage\\Entity\\Picking\\Queue',
              event: 'storage.tasks.queue.opened',
              data
            }
          })

          this.action = 'next'

          if (this.helper) {
            this.removeHelper(this.helper.id)
          }
        })
    },
    handleBarcode (barcode) {
      this.barcode = barcode
    }
  }
}
</script>
